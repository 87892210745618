import React, {useState, useEffect} from 'react';
import Lottie from 'react-lottie';
import * as animationData from './../../assets/json/Circle.json'
import ReactPlayer from 'react-player';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import './RadioPlayer.css';

const RadioPlayer = () => {
    const streamUrl = 'https://stream-167.zeno.fm/xbohf8qf5uauv';
    const [isPlaying, setIsPlaying] = useState(false);
    const [generatedUrl, setGeneratedUrl] = useState(null);
    const [lastFetchTime, setLastFetchTime] = useState(0); // تخزين الوقت الأخير لتحديث الرابط
    const [currentTime, setCurrentTime] = useState(new Date().toLocaleString('en-US', { timeZone: 'Europe/Istanbul' }));

    const togglePlay = () => {
        setIsPlaying(!isPlaying);
    };

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    useEffect(() => {
        const fetchGeneratedUrl = async () => {
            try {
                const response = await axios.get(streamUrl);
                setGeneratedUrl(response.data.url);
                setLastFetchTime(Date.now()); // تحديث الوقت الأخير للحصول على الرابط
            } catch (error) {
                console.error('حدث خطأ أثناء التحميل:', error);
            }
        };

        // إعادة تحميل الرابط فقط إذا مرت 3600000 مللي ثانية منذ آخر تحديث
        const elapsedTime = Date.now() - lastFetchTime;
        if (elapsedTime >= 3600000 || !generatedUrl) {
            fetchGeneratedUrl();
        }

        // إعادة تشغيل هذا المؤثر كلما تم تغيير الرابط
    }, [streamUrl, generatedUrl, lastFetchTime]);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentTime(new Date().toLocaleString('en-US', { timeZone: 'Europe/Istanbul' }));
        }, 1000);

        return () => clearInterval(interval);
    }, []);
    return (
        <div className="radio-player-container">
            <div className="current-time web">{currentTime}</div>
            <div className="background-container web-background"></div>
            <div className="background-container mobile-background"></div>
            <div className="lottie-container">
                <Lottie
                    options={defaultOptions}
                />
            </div>
            <ReactPlayer
                url={generatedUrl || streamUrl}
                playing={isPlaying}
                width="100%"
                height="100px"
            />
            <button onClick={togglePlay} className="play-pause-button">
                <div className="icon-container">
                    {isPlaying ? <FontAwesomeIcon icon={faPause} /> : <FontAwesomeIcon icon={faPlay} />}
                </div>
            </button>
        </div>
    );
};

export default RadioPlayer;
