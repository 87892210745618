import React from 'react';
import RadioPlayer from "./components/RadioPlayer/RadioPlayer";

function App() {
    return (
        <div className="App">
            <RadioPlayer />
        </div>
    );
}

export default App;
